import React, { useCallback, useMemo } from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { navigate } from 'gatsby';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { TagLink } from 'components/shared';
import { getHtmlSerializer } from 'utils/serialize/htmlSerializer';
import { BODY2, ContainerProps } from '@tuunetech/tuune-components';
import {
  ImgWrapper,
  Title,
  PrimaryTitle,
  TagsWrapper,
  PreviewWrapper,
} from './styles';

type PostPreviewProps = {
  image?: FluidObject;
  category?: {
    name: string;
    path: string;
  };
  title: string;
  author?: string;
  excerpt?: RichTextBlock[];
  path: string;
  isPrimary?: boolean;
};

export const PostPreview: React.FC<
  PostPreviewProps & Partial<ContainerProps>
> = ({
  image,
  title,
  author,
  excerpt,
  path,
  isPrimary = false,
  category,
  ...containerProps
}) => {
  const TitleComponent = useMemo(() => (isPrimary ? PrimaryTitle : Title), [
    isPrimary,
  ]);

  const handleOpenPost = useCallback(() => {
    return navigate(path);
  }, [path]);

  return (
    <PreviewWrapper $isPrimary={isPrimary} disableGutters {...containerProps}>
      {image && (
        <ImgWrapper onClick={handleOpenPost} $isPrimary={isPrimary}>
          <Img fluid={{ ...image, aspectRatio: isPrimary ? 2.2 : 1.7 }} />
        </ImgWrapper>
      )}
      {category && (
        <TagsWrapper $isPrimary={isPrimary}>
          <TagLink to={`/${category.path}`}>{category.name}</TagLink>
        </TagsWrapper>
      )}
      <TitleComponent onClick={handleOpenPost} $isPrimary={isPrimary}>
        {title}
      </TitleComponent>
      {excerpt && (
        <RichText render={excerpt} htmlSerializer={getHtmlSerializer()} />
      )}
      {author && isPrimary && (
        <BODY2 $marginTop={28}>Written by {author}</BODY2>
      )}
    </PreviewWrapper>
  );
};
