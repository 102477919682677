import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearch } from 'hooks';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useForm } from 'react-hook-form';
import { graphql, useStaticQuery } from 'gatsby';
import { SEODStaticData } from 'types';
import {
  Footer,
  Layout as SharedLayout,
  SeoProps,
  SEO,
} from '@tuunetech/tuune-components';
import { Header } from '../Header';
import { Link } from '../Link';

const mainWebsiteUrl = process.env.GATSBY_LANDING_PAGE_DOMAIN
  ? `https://${process.env.GATSBY_LANDING_PAGE_DOMAIN}`
  : undefined;

const LINKS = [
  {
    sectionTitle: 'about',
    links: [
      {
        name: 'Careers',
        link: `https://www.linkedin.com/company/tuunehealth/jobs/`,
        target: '_blank',
      },
      {
        name: 'Contact us',
        link: mainWebsiteUrl ? `${mainWebsiteUrl}/contact/` : '',
        target: '_blank',
      },
    ],
  },
  {
    sectionTitle: 'connect',
    links: [
      {
        name: 'Instagram',
        link: 'https://www.instagram.com/tuune_/',
        target: '_blank',
      },
      {
        name: 'Facebook',
        link: 'https://www.facebook.com/tuunehealth/',
        target: '_blank',
      },
      {
        name: 'Twitter',
        link: 'https://twitter.com/Tuunehealth/',
        target: '_blank',
      },
    ],
  },
];

const POLICY_LINKS = [
  {
    name: 'Privacy Policy',
    link: mainWebsiteUrl ? `${mainWebsiteUrl}/privacy-policy/` : '',
    target: '_blank',
  },
  {
    name: 'Cookie Policy',
    link: mainWebsiteUrl ? `${mainWebsiteUrl}/cookie-policy/` : '',
  },
];

interface LayoutProps {
  path?: string;
  isNotFound?: boolean;
  seoData?: SeoProps;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  path = '/',
  seoData,
}) => {
  const { isSearchActive: isBackdropOpen } = useSearch();
  const [signUpResult, setSignUpResult] = useState('');

  const { SEO: defaultSeoData } = useStaticQuery<{
    SEO: SEODStaticData;
  }>(graphql`
    query {
      SEO: site {
        siteMetadata {
          title
          description
          siteUrl
          twitterUsername
        }
      }
    }
  `);

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    mode: 'all',
  });

  const watchEmail = watch('email');
  const submit = useCallback(async ({ email }) => {
    const result = await addToMailchimp(email);

    setSignUpResult(result.result);
  }, []);

  useEffect(() => {
    // reset previous sign up result when enter new email
    if (signUpResult && watchEmail !== '') {
      setSignUpResult('');
    }
  }, [signUpResult, watchEmail]);

  useEffect(() => {
    if (signUpResult === 'success') {
      setValue('email', '');
    }
  }, [setValue, signUpResult]);

  const emailFieldRef = register({
    required: 'Email is required.',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Please use a valid email.',
    },
  });

  const emailFieldProps = useMemo(
    () => ({
      name: 'email',
      placeholder: 'Email Address',
      type: 'email',
      inputRef: emailFieldRef,
      helperText:
        errors.email?.message ||
        (signUpResult === 'success'
          ? 'Thank you!'
          : signUpResult === 'error'
          ? 'Sign up was unsuccessful'
          : undefined),
      error: !!errors.email?.message || signUpResult === 'error',
    }),
    [signUpResult, errors, emailFieldRef],
  );

  const seo = useMemo(() => {
    const { title, description, siteUrl } = defaultSeoData.siteMetadata;

    return {
      isArticle: seoData?.isArticle,
      title: seoData?.title || title,
      description: seoData?.description || description,
      image: seoData?.image,
      url: seoData?.url || `${siteUrl}${path}`,
      lang: seoData?.lang || 'en',
      twitter: seoData?.twitter,
    };
  }, [defaultSeoData, seoData, path]);

  return (
    <SharedLayout
      isBackdropOpen={isBackdropOpen}
      footer={
        <Footer
          linkRenderer={({ name, target, link }) => (
            <Link
              key={name}
              to={typeof link === 'string' ? link : ''}
              onClick={typeof link === 'function' ? link : undefined}
              target={target}
              underline="hover"
              display="inline"
              variant="caption"
              trackingData={{
                event: 'cta',
                cta_position: 'footer',
                cta_text: name,
              }}
            >
              {name}
            </Link>
          )}
          links={{ main: LINKS, policy: POLICY_LINKS }}
          mailchimpReg={{
            handleEmailSubmit: handleSubmit(submit),
            emailFieldProps,
          }}
        />
      }
      header={<Header path={path} />}
    >
      <SEO {...seo} />
      {children}
    </SharedLayout>
  );
};
