import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { Elements, HTMLSerializer } from 'prismic-reactjs';
import { BODY1, H1, H2, H3, H4 } from '@tuunetech/tuune-components';
import {
  Link,
  List,
  ListItem,
  OList,
  OListItem,
  PostImage,
  Quote,
  QuoteAuthor,
} from 'components/shared';

export const htmlSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  switch (type) {
    case Elements.paragraph:
      return <BODY1 key={key}>{children}</BODY1>;
    case Elements.heading2:
      return <H2 key={key}>{children}</H2>;
    case Elements.heading3:
      return <H3 key={key}>{children}</H3>;
    case Elements.heading4:
      return <H4 key={key}>{children}</H4>;
    case Elements.hyperlink:
      // for external links
      // for internal links use linkResolver
      return (
        <Link key={key} to={element.data.url} target={element.data.target}>
          {children}
        </Link>
      );
    case Elements.image:
      const props = { src: element.url, alt: element.alt || '' };
      return <PostImage {...Object.assign(props || {}, { key })} />;

    case Elements.list:
      return <List key={key}>{children}</List>;

    case Elements.oList:
      return <OList key={key}>{children}</OList>;

    case Elements.listItem:
      return (
        <ListItem key={key} $key={key}>
          <BODY1>{children}</BODY1>
        </ListItem>
      );
    case Elements.oListItem:
      return (
        <OListItem key={key} $key={key}>
          <BODY1>{children}</BODY1>
        </OListItem>
      );
  }
};

type ReplaceElements = Record<
  string,
  { Component?: React.ComponentType; props?: Record<string, string | number> }
>;

export const getHtmlSerializer = (
  elements: ReplaceElements = {},
): HTMLSerializer<ReactNode> => {
  const PARAGRAPH = {
    Component: elements[Elements.paragraph]?.Component || BODY1,
    props: elements[Elements.paragraph]?.props,
  };

  const HEADING1 = {
    Component: elements[Elements.heading1]?.Component || H1,
    props: elements[Elements.heading1]?.props,
  };

  const HEADING2 = {
    Component: elements[Elements.heading2]?.Component || H2,
    props: elements[Elements.heading2]?.props,
  };

  const O_LIST_ITEM = {
    Component: elements[Elements.oListItem]?.Component || BODY1,
    props: elements[Elements.oListItem]?.props,
  };

  // eslint-disable-next-line react/display-name
  return (type, element, content, children, key) => {
    switch (type) {
      case Elements.paragraph:
        return (
          <PARAGRAPH.Component key={key} {...PARAGRAPH.props}>
            {children}
          </PARAGRAPH.Component>
        );
      case Elements.heading1:
        return (
          <HEADING1.Component key={key} {...HEADING1.props}>
            {children}
          </HEADING1.Component>
        );
      case Elements.heading2:
        return (
          <HEADING2.Component key={key} {...HEADING2.props}>
            {children}
          </HEADING2.Component>
        );
      case Elements.heading3:
        return <H3 key={key}>{children}</H3>;
      case Elements.heading4:
        return <H4 key={key}>{children}</H4>;
      case Elements.label:
        if (element.data.label === 'quote') {
          return <Quote key={key}>{children}</Quote>;
        }
        if (element.data.label === 'quote-author') {
          return <QuoteAuthor key={key}>{children}</QuoteAuthor>;
        }
        if (element.data.label === 'dialog-person') {
          return (
            <Typography key={key} component="span" color="secondary">
              <strong>{children}</strong>
            </Typography>
          );
        }
        return element;
      case Elements.hyperlink:
        // for external links
        // for internal links use linkResolver
        return (
          <Link key={key} to={element.data.url} target={element.data.target}>
            {children}
          </Link>
        );
      case Elements.image:
        const props = { src: element.url, alt: element.alt || '' };
        return <PostImage {...Object.assign(props || {}, { key })} />;

      case Elements.list:
        return <List key={key}>{children}</List>;

      case Elements.oList:
        return <OList key={key}>{children}</OList>;

      case Elements.listItem:
        return (
          <ListItem key={key} $key={key}>
            <BODY1>{children}</BODY1>
          </ListItem>
        );
      case Elements.oListItem:
        return (
          <OListItem key={key} $key={key}>
            <O_LIST_ITEM.Component {...O_LIST_ITEM.props}>
              {children}
            </O_LIST_ITEM.Component>
          </OListItem>
        );
    }
  };
};
