import React from 'react';
import { Fade, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import Chevron from '@material-ui/icons/ChevronRightRounded';
import { useSearch } from 'hooks';
import { GridContainer } from '@tuunetech/tuune-components';
import { Category, StyledGridItem, TagsContainer } from './styles';
// import { Search } from '../Search';
import { Layout } from 'types';

export const Categories: React.FC<{
  path: string;
  layout: Layout;
}> = ({ path, layout }) => {
  const { isSearchActive } = useSearch();
  const isBigScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('lg');
  });

  return (
    <TagsContainer fixed>
      <GridContainer justify="center">
        {layout.prismicLayout.data.categories.map(
          ({ category: { document } }) => (
            <Fade in={!isBigScreen || !isSearchActive} key={document.uid}>
              <StyledGridItem xs={12} md="auto">
                <Category
                  to={`/${document.uid}`}
                  underline="none"
                  color="primary"
                  $isActive={path?.includes(document.uid)}
                  trackingData={{
                    event: 'cta',
                    cta_position: 'header',
                    cta_text: document.data.name,
                  }}
                >
                  {document.data.name}
                </Category>
                <Hidden mdUp implementation="css">
                  <Chevron color="primary" fontSize="small" />
                </Hidden>
              </StyledGridItem>
            </Fade>
          ),
        )}
        {/*<Hidden mdDown>*/}
        {/*  <StyledGridItem $search>*/}
        {/*    <Search />*/}
        {/*  </StyledGridItem>*/}
        {/*</Hidden>*/}
      </GridContainer>
    </TagsContainer>
  );
};
