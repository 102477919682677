import React, { useCallback } from 'react';
import { GatsbyLinkProps } from 'gatsby';
import { LinkProps as MuiLinkProps } from '@material-ui/core';
import { GatsbyLink, MuiLink } from './styles';
import { useAnalytics } from 'utils/analytics';

type LinkProps = GatsbyLinkProps<Record<string, unknown>> &
  MuiLinkProps & {
    trackingData?: Record<string, string>;
  };

export const Link: React.FC<LinkProps> = ({
  children,
  // Gatsby Internal Link Props
  to,
  onClick,
  replace,
  state,
  activeClassName,
  activeStyle,
  partiallyActive,
  // analytics tracking data
  trackingData,
  //  Mui Styling Link Props
  ...props
}) => {
  const analytics = useAnalytics();

  const handleClick = useCallback(
    event => {
      if (trackingData) {
        analytics.track(trackingData);
      }
      if (onClick) {
        onClick(event);
      }
    },
    [onClick, trackingData, analytics],
  );

  return (
    <MuiLink
      href={to || undefined}
      onClick={handleClick}
      InternalLinkComponent={
        <GatsbyLink
          to={to}
          replace={replace}
          state={state}
          activeClassName={activeClassName}
          activeStyle={activeStyle}
          partiallyActive={partiallyActive}
        >
          {children}
        </GatsbyLink>
      }
      {...props}
    >
      {children}
    </MuiLink>
  );
};
