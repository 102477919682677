import React from 'react';
import { GridContainer, GridItem, H3 } from '@tuunetech/tuune-components';
import { Post } from 'types';
import { Pagination } from '@material-ui/lab';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { navigate } from 'gatsby';
import { PostPreview } from '../PostPreview';

export type PostsListProps = {
  posts: Post[];
  count: number;
  page: number;
  path: string;
};

export const PostsList: React.FC<PostsListProps> = ({
  posts,
  count,
  page,
  path,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  return posts.length === 0 ? (
    <GridContainer>
      <GridItem>
        <H3 color="textSecondary">No posts here yet</H3>
      </GridItem>
    </GridContainer>
  ) : (
    <>
      <GridContainer>
        {posts.map(({ data, uid }) => (
          <GridItem key={data.title.text} lg={4} md={6} xs={12}>
            <PostPreview
              path={`/${data.category.document.uid}/${uid}`}
              category={{
                name: data.category.document.data.name,
                path: data.category.document.uid,
              }}
              image={data.hero_image.fluid}
              title={data.title.text}
              excerpt={data.stand_first.raw}
              $marginBottom={isMobile ? 50 : 70}
            />
          </GridItem>
        ))}
      </GridContainer>
      {count > 1 && (
        <GridContainer justify="center">
          <GridItem>
            <Pagination
              count={count}
              page={page}
              color="primary"
              onChange={(e, page) =>
                navigate(page === 1 ? path : `/page${page}`)
              }
            />
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};
