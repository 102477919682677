import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '../shared/Link';

export const Logo: React.FC = () => {
  const layout = useStaticQuery(
    graphql`
      query {
        prismicLayout {
          data {
            site_logo {
              alt
            }
          }
        }

        svg: file(relativePath: { eq: "logo.svg" }) {
          publicURL
        }
      }
    `,
  );

  return (
    <Link
      to="/"
      trackingData={{ event: 'cta', cta_position: 'header', cta_text: 'Logo' }}
    >
      <img
        src={layout.svg.publicURL}
        alt={layout.prismicLayout.data.site_logo.alt || 'Tuune blog logo'}
      />
    </Link>
  );
};
