import styled from 'styled-components';

export const ListItem = styled.li<{ $key: string }>``;

export const OListItem = styled(ListItem)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 22px;
  }
`;

export const List = styled.ul`
  color: ${({ theme }) => theme.palette.common.purple[900]};
  padding-left: 20px;
`;

export const OList = styled.ol`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-left: 35px;
`;
