import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { BODY2 } from '@tuunetech/tuune-components';

export const Quote = styled(Typography).attrs({ component: 'div' })`
  font-size: 24px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-style: italic;
  margin-top: 52px;
  display: block;
`;

export const QuoteAuthor = styled(BODY2).attrs({ component: 'div' })`
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  margin: 25px auto 54px;
  display: block;
`;
