import styled from 'styled-components';
import { BaseLink } from '@tuunetech/tuune-components';
import { Link } from 'gatsby';

export const MuiLink = styled(BaseLink)`
  font-size: inherit;
`;

export const GatsbyLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`;
