import styled from 'styled-components';
import { Link } from 'components/shared';

export const TagLink = styled(Link).attrs({
  color: 'primary',
  underline: 'hover',
})`
  display: inline-block;
  margin-right: 30px;

  :last-of-type {
    margin-right: 0;
  }
`;
