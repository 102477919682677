import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
// import { Box } from '@material-ui/core';
import { Header as BaseHeader, HeaderLinks } from '@tuunetech/tuune-components';
// import { BlogTags } from './BlogTags';
// import { Search } from './Search';
import { Logo } from '../../Logo';
import { Link } from '../Link';
import { Categories } from './Categories';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout } from 'types';

export const HEADER_LINKS_BLOG = {
  left: [
    {
      link: process.env.GATSBY_LANDING_PAGE_DOMAIN
        ? `https://${process.env.GATSBY_LANDING_PAGE_DOMAIN}`
        : '/',
      name: 'Home',
    },
  ],
  right: [
    {
      link: process.env.GATSBY_LANDING_PAGE_DOMAIN
        ? `https://app.tuune.com/get-started`
        : '',
      name: 'GET STARTED',
    },
  ],
};

export const Header: React.FC<{ path: string }> = ({ path }) => {
  const linkRenderer = useCallback(
    (link, name) => (
      <Link
        to={link}
        underline="none"
        variant="button"
        trackingData={{
          event: 'cta',
          cta_position: 'header',
          cta_text: name,
        }}
      >
        {name}
      </Link>
    ),
    [],
  );

  const categoryLayout = useStaticQuery<Layout>(
    graphql`
      query {
        prismicLayout {
          data {
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    _previewable
                    id
                    uid
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  const categories = categoryLayout.prismicLayout?.data?.categories?.map(
    ({ category: { document } }) => ({
      name: document.data.name,
      link: `/${document.uid}`,
    }),
  );

  return (
    <BaseHeader
      color="dark"
      logo={<Logo />}
      desktop={{
        left: (
          <HeaderLinks
            links={HEADER_LINKS_BLOG.left}
            linkRenderer={linkRenderer}
          />
        ),
        right: (
          <HeaderLinks
            links={HEADER_LINKS_BLOG.right}
            linkRenderer={linkRenderer}
          />
        ),
        bottom: <Categories path={path} layout={categoryLayout} />,
      }}
      mobile={{}}
      mobileNav={[
        ...categories,
        { name: 'divider', link: '' },
        ...HEADER_LINKS_BLOG.right,
        ...HEADER_LINKS_BLOG.left,
      ]}
      isAlwaysVisible
      onRoute={navigate}
    />
  );
};
