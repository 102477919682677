import { css } from 'styled-components';
import { Container } from '@tuunetech/tuune-components';

export const PostSectionStyle = css`
  padding-top: 40px;
  padding-bottom: 40px;

  ${Container} + ${Container} {
    border-top: 1px solid ${({ theme }) => theme.palette.border.light};
  }

  ${Container}:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.light};
  }

  border-top: 1px solid ${({ theme }) => theme.palette.border.light};
`;
