import styled from 'styled-components';
import { Container, GridItem } from '@tuunetech/tuune-components';
import { Link } from 'components/shared';

export const TagsContainer = styled(Container)`
  ::before {
    content: '';
    display: block;
    height: 1px;
    min-width: 100vw;
    background-color: ${({ theme }) => theme.palette.border.dark};
    margin-bottom: 30px;
  }

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px auto 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    ::before {
      display: none;
    }

    position: relative;
    margin-bottom: 26px;
    margin-top: 10px;
    height: fit-content !important;
    display: block;
  }
`;

export const StyledGridItem = styled(GridItem)<{ $search?: boolean }>`
  display: flex;
  align-items: center;

  position: ${({ $search }) => ($search ? 'absolute' : 'static')};
  right: 22px;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`;

export const Category = styled(Link)<{ $isActive: boolean }>`
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.text.primary}!important;
  margin: 0 20px;
  position: relative;
  display: flex;
  justify-content: center;

  :after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.background.dark};
    position: absolute;
    bottom: 0;
    display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
  }

  :hover,
  :active {
    :after {
      display: block;
    }
  }
`;
