import styled, { css } from 'styled-components';
import { BODY1, Container, H2, H4 } from '@tuunetech/tuune-components';

type Preview = {
  $isPrimary: boolean;
};

export const TagsWrapper = styled.div<Preview>`
  display: flex;
  width: 100%;
  justify-content: ${({ $isPrimary }) => ($isPrimary ? 'center' : 'left')};
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`;

const TitleCss = css<Preview>`
  & + ${BODY1} {
    margin-top: 20px;
  }

  text-align: ${({ $isPrimary }) => ($isPrimary ? 'center' : 'left')};
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
  }
`;

export const PrimaryTitle = styled(H2)`
  margin-top: 13px;
  ${TitleCss};
`;

export const Title = styled(H4)`
  margin-top: 8px;
  ${TitleCss};
`;

export const ImgWrapper = styled.div<Preview>`
  cursor: pointer;
  margin-bottom: ${({ $isPrimary }) => ($isPrimary ? '37px' : '28px')};
`;

export const PreviewWrapper = styled(Container)<Preview>`
  ${BODY1} {
    text-align: ${({ $isPrimary }) => ($isPrimary ? 'center' : 'left')};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${BODY1} {
      text-align: left;
    }
  }
`;
